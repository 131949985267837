<template>
  <div>
    <div>
      <table>
        <tr>
          <th>Промокод</th>
          <th>Условия</th>
          <th>Размер скидки</th>
          <th>Ед. изм.</th>
        </tr>
        <tr v-for="(codeObj, key) in data" @click="$router.push('/marketing/promocode/'+codeObj.id)">
          <td>{{ codeObj.code }}</td>
          <td></td>
          <td>{{ codeObj.promo_content }}</td>
          <td>{{ codeObj.promo_type | promocodeType }}</td>
        </tr>
      </table>
    </div>

    <a class="button_add_promocode" @click="addPromocode">Добавить промокод</a>
  </div>
</template>

<script>
import MarketingAPI from "../../../../api/MarketingAPI";

export default {
  data(){
    return{
      data: []
    }
  },
  methods: {
    getData: function (){
      MarketingAPI.get_promocodes()
          .then(response => {
            this.data = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    addPromocode: function (){
      this.$router.push('/marketing/promocode/add')
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;


  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
}
tr{
  &:hover{
    cursor: pointer;
    & td{
      background: #fff5ee;
      &.edit{
        cursor: pointer;
      }
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    & i{
      margin: 0 auto;
      display: block;
    }
  }
}
.button_add_promocode{
  width: 100%;
  padding: 12px 15px;
  display: block;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  background: #05a545;
  color: #fff;
  font-size: 90%;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
    background: #047f33;
  }
}
</style>