<template>
  <div>
    <wrapper_navigation
      :element-header="TextLocalization.TransferFundsToTheAccountOfALegalEntity[language]"
      :description="TextLocalization.AddingFundsToTheBalanceOfTheApplicationOfALegalEntity[language]"
      @click.native="open_page('add-payment')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.AddThePromisedPaymentToTheBalanceOfTheLegalEntity[language]"
        :description="TextLocalization.AddingThePromisedPaymentToTheBalanceOfTheLegalEntityApplication[language]"
        @click.native="open_page('add-promised-payment')"
    />
    <wrapper_navigation
        v-if=" 1 === 2"
        element-header="Удалить ошибочную оплату с баланса юридического лица"
        description="Удаление ошибочных оплат с баланса приложения юридического лица"
        @click.native="open_page('delete-payment')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.RegisterOfCompletedActsOfWorkPerformedForThePeriod[language]"
        :description="TextLocalization.InformationAboutAllTheWorkPerformedToAllPartnersForThePeriod[language]"
        @click.native="open_page('register-completed-work')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.RegisterOfPaymentsReceivedForThePeriod[language]"
        :description="TextLocalization.InformationAboutAllBalanceTopUpsFromPartnersForThePeriod[language]"
        @click.native="open_page('register-received-payments')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.AddCompletedAct[language]"
        :description="TextLocalization.FullDescriptionAddCompletedAct[language]"
        @click.native="open_page('add-completed-work-act')"
    />
    <wrapper_navigation
        element-header="Актуальный прайс лист"
        description=""
        @click.native="open_page('price-list')"
    />
    <wrapper_navigation
        element-header="Тарифы"
        description=""
        @click.native="open_page('tarifs')"
    />
  </div>
</template>

<script>
import wrapper_navigation from '@/components/elements/wrapper_navigation';

export default {
  components: {
    wrapper_navigation
  },
  methods: {
    open_page: function (pageName){
      this.$router.push(this.$route.path+'/'+pageName);
    },
  }
}
</script>