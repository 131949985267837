import { render, staticRenderFns } from "./segments.vue?vue&type=template&id=917d0fac&scoped=true&"
import script from "./segments.vue?vue&type=script&lang=js&"
export * from "./segments.vue?vue&type=script&lang=js&"
import style0 from "./segments.vue?vue&type=style&index=0&id=917d0fac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "917d0fac",
  null
  
)

export default component.exports