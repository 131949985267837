var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in"},[_c('a-row',{staticStyle:{"height":"calc(100vh - 90px)"},attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{span: 12, offset: 0},"xl":{span: 7, offset: 2}}},[_c('h2',{staticClass:"mb-15"},[_vm._v("Войти")]),_c('h6',{staticClass:"font-regular text-muted info_text mb-15"},[_vm._v("Введите свой логин и пароль чтобы авторизироваться в системе")]),(_vm.authErrorText !== null)?_c('a-alert',{staticClass:"alert_warning_auth_status",attrs:{"message":_vm.authErrorText,"type":"error"}}):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","hideRequiredMark":true},on:{"submit":_vm.auth}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Логин","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'login',
						{ rules: [{ required: true, message: 'Пожалуйста введите свой логин!' }] },
						]),expression:"[\n\t\t\t\t\t\t'login',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Пожалуйста введите свой логин!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Логин"},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"Пароль","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [{ required: true, message: 'Пожалуйста введите свой пароль!' }] },
						]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Пожалуйста введите свой пароль!' }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Пароль"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" Войти ")])],1),_c('div',{staticClass:"help_buttons"},[_c('div',{staticClass:"guest_enter",on:{"click":_vm.enterLikeGuest}},[_vm._v(" Войти как гость ")]),_c('div',{staticClass:"remind_my_password",on:{"click":function($event){return _vm.$router.push({name:'RestorePassword'})}}},[_vm._v(" Восстановить пароль ")])])],1)],1),_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }