<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-form-model-item label="Наименование">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Description[this.language]">
        <a-input v-model="form.description" />
      </a-form-model-item>

      <a-form-model-item label="application_version_column_name">
        <a-input v-model="form.application_version_column_name" v-if="this.$route.params.id === undefined"/>
        <a-input v-model="form.application_version_column_name" v-else readOnly/>
      </a-form-model-item>

      <a-form-model-item label="application_tariff_column_name">
        <a-input v-model="form.application_tariff_column_name" v-if="this.$route.params.id === undefined"/>
        <a-input v-model="form.application_tariff_column_name" v-else readOnly/>
      </a-form-model-item>

      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.id !== undefined ? 12 : 24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.id === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.id !== undefined">
            <a-button type="danger" @click="showModalDelete">
              {{TextLocalization.Delete[language]}}
            </a-button>
          </a-col>
        </a-row>

      </a-form-model-item>
    </a-col>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>

<script>
import DevelopmentAPI from "../../../../api/DevelopmentAPI";

export default {
  data(){
    return {
      visible: false,
      confirmLoading: false,
      form: {
        name: null
      }
    }
  },

  created() {
    if (this.$route.params.id !== undefined) {
      this.getInfo();
    }
  },
  methods: {
    onSubmit() {
      if (this.$route.params.id === undefined){
        this.add(this.form);
      } else {
        this.edit(this.form);
      }
    },
    getInfo(){
      DevelopmentAPI.get_function(this.$route.params.id)
          .then(response => {
            this.form = response.data.data;

          })
          .catch((e) => {
            console.log(e);
          });
    },
    add(){
      DevelopmentAPI.add_function(this.form)
          .then(response => {
            this.form = response.data.data;
            this.$router.push('/development/functions/'+this.form.id);
            this.$root.$emit("createAlertGood");

          })
          .catch((e) => {
            console.log(e);
          });
    },
    edit(){
      DevelopmentAPI.edit_function(this.form)
          .then(response => {
            this.form = response.data.data;
            this.$root.$emit("createAlertGood");
          })
          .catch((e) => {
            console.log(e);
          });
    },
    delete: function (){
      this.form.is_deleted = true;
      this.edit();
      this.$router.push('/development/functions/');
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    }
  }
}
</script>

<style scoped lang="scss">
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
</style>