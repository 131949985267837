import BaseApi from "./BaseApi";

class MenuApi extends BaseApi {

    get_menu(id) {
        return this.axios.get('/Menu/'+id);
    }
    add_category(data) {
        return this.axios.post('/menu/category/add',data );
    }
    get_category(id) {
        return this.axios.get('/Menu/category/'+id);
    }
    edit_category(data) {
        delete data.branch_stop;
        delete data.dishes;
        delete data.categories;
        return this.axios.post('/Menu/category/edit', data);
    }

    delete_category(data) {
        return this.axios.post('/Menu/category/delete', data);
    }

    get_catalog(id) {
        return this.axios.get('/Menu/'+id);
    }

    edit_branch_stop_category(data, id) {
        return this.axios.post('/Menu/category/edit-branch-stop', {id: id, branch_stop: data});
    }

}

export default new MenuApi();
