<template>
  <div>
    <wrapper_navigation
        element-header="Публичный договор"
        description=""
        @click.native="openPage('/legal/public-contract')"
    />

    <wrapper_navigation
        element-header='Действующие тарифы ООО "ГастроСофт"'
        description=""
        @click.native="openPage('/legal/tariffs')"
    />

    <wrapper_navigation
        element-header="Политика конфиденциальности"
        description=""
        @click.native="openPage('/legal/privacy-policy')"
    />

    <wrapper_navigation
        element-header='Свидетельство о государственной регистрации ООО "ГастроСофт"'
        description=""
        @click.native="openPage('/pdf/registration.pdf', '_blank')"
    />

    <wrapper_navigation
        element-header='Приказ о назначении директора ООО "ГастроСофт"'
        description=""
        @click.native="openPage('/pdf/director.pdf', '_blank')"
    />

  </div>
</template>

<script>
import wrapper_navigation from '@/components/elements/wrapper_navigation';

export default {
  components: {
    wrapper_navigation
  },
  methods: {
    openPage(page, target){
      if(target === '_blank'){
        window.open(page, '_blank');
      }else {
        this.$router.push(page);
      }
    }
  }
}
</script>

<style scoped>

</style>