<template>
  <div>
    <table>
      <tr>
        <th>№</th>
        <th>Наименование услуги</th>
        <th>Действия</th>
      </tr>
      <tr v-if="tarifsList.length > 0" v-for="(priceInfo, priceInfoKey) in tarifsList" :key="priceInfoKey" @click="goTo('/bookkeeping/tarifs/'+priceInfo.id)">
        <td>{{ priceInfoKey + 1 }}</td>
        <td>{{ priceInfo.name }}</td>
        <td>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="fill-gray-7" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"/>
            <path class="fill-gray-7" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"/>
          </svg>
        </td>
      </tr>
    </table>



    <a-row type="flex" :gutter="24" class="buttons_container mt-25">
      <a-col :span="24" :lg="24" :md="24">
        <a-button type="primary" @click="goTo('/bookkeeping/price-list/add')">
          Добавить строку в прайс-лист
        </a-button>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import ApplicationApi from "../../../../api/ApplicationApi";

export default {
  data(){
    return{
      tarifsList: []
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo(){
      ApplicationApi.getApplicationTariffsAvailable()
          .then(response => {
            this.tarifsList = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    goTo: function (data){
      this.$router.push(data);
    },
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;
  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
  & tr{
    cursor: pointer;

    &:hover{
      & td{
        background: #fff5ee;
      }
    }

    & td{
      border: 1px solid #eee;
      font-size: 14px;
      padding: 10px;
    }
  }
}
</style>