<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.id" />
      <a-input type="hidden" v-model="form.modifier_group_id" />
      <a-form-model-item :label="TextLocalization.ModifierName[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Weight[language]">
        <a-input v-model="form.weight" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Price[language]">
        <a-input type="number" step="0.01" v-model="form.price" />
      </a-form-model-item>
      <a-form-model-item label="Нет в наличии">
        <div>
          <a-switch v-model="form.in_stop_list" />
        </div>
      </a-form-model-item>

      <div
        v-if="
          this.$route.params.modifierID !== undefined &&
            this.application_settings.available_branch_stop === true
        "
      >
        <stop_branch
            v-show="!form.in_stop_list"
            header="Доступно на филиалах:"
            :application_branches="application_branches"
            :statuses = "form.branch_stop"
            @changeData="changeBranchStop"
        />
      </div>

      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col
            :span="24"
            :lg="this.$route.params.modifierID !== undefined ? 12 : 24"
            :md="24"
          >
            <a-button type="primary" @click="onSubmit">
              {{
                this.$route.params.modifierID === undefined
                  ? this.TextLocalization.Add[this.language]
                  : this.TextLocalization.Edit[this.language]
              }}
            </a-button>
          </a-col>
          <a-col
            :span="24"
            :lg="12"
            :md="24"
            v-if="this.$route.params.modifierID !== undefined"
          >
            <a-button type="danger" @click="showModalDelete">
              {{ TextLocalization.Delete[language] }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>
    <a-modal
      :title="TextLocalization.AreYouSure[language]"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>
import ModifiersApi from "../../../api/ModifiersApi";
import partnerIDMixin from "@/mixins/partnerID";
import stop_branch from "./stop_branch";
import ApplicationApi from "../../../api/ApplicationApi";
import ApplicationBranchesApi from "../../../api/ApplicationBranchesApi";

export default {
  mixins: [partnerIDMixin],
  components: {
    stop_branch,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      dish_id: null,
      application_settings: null,
      application_branches: [],
      available_on_branches: [],
      form: {},
    };
  },
  methods: {
    onSubmit() {
      if (this.$route.params.modifierID === undefined) {
        this.add(this.form);
      } else {
        this.edit(this.form);
      }
    },
    get(id) {
      ModifiersApi.get(id)
        .then((response) => {
          response.status === 401 ? this.$store.commit("User/EXIT_USER") : null;
          switch (response.data.status) {
            case 200:
              this.form = response.data.data;
              break;
            default:
              this.data = {};
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    edit(data) {
      ModifiersApi.edit(data)
        .then((response) => {
          switch (response.data.status) {
            case 200:
              this.form = response.data.data;
              this.$root.$emit("createAlertGood");
              break;
            default:
              this.data = {};
              this.$root.$emit("createAlertError");
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    add(data) {
      ModifiersApi.add(data)
        .then((response) => {
          response.status === 401 ? this.$store.commit("User/EXIT_USER") : null;
          switch (response.data.status) {
            case 200:
              this.form = response.data.data;
              this.$store.dispatch("BackButton/setGoBack", -2);
              switch (this.$route.meta.layout) {
                case "administrator":
                  this.$router.push(
                    "/applications/" +
                      this.partner_id +
                      "/category/" +
                      this.$route.params.catID +
                      "/dish/" +
                      this.$route.params.dishID +
                      "/modifier-group/" +
                      this.$route.params.modifierGroupID +
                      "/modifier/" +
                      response.data.data.id
                  );
                  break;
                case "manager":
                  this.$router.push(
                    "/menu/category/" +
                      this.$route.params.catID +
                      "/dish/" +
                      this.$route.params.dishID +
                      "/modifier-group/" +
                      this.$route.params.modifierGroupID +
                      "/modifier/" +
                      response.data.data.id
                  );
                  break;
              }
              this.$root.$emit("createAlertGood");
              break;
            default:
              this.data = {};
              this.$root.$emit("createAlertError");
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    delete(data) {
      ModifiersApi.delete(data)
        .then((response) => {
          response.status === 401 ? this.$store.commit("User/EXIT_USER") : null;
          switch (response.data.status) {
            case 200:
              switch (this.$store.getters["BackButton/goBack"]) {
                case -1:
                  this.$store.dispatch("BackButton/setGoBack", -3);
                  break;
                case -2:
                  this.$store.dispatch("BackButton/setGoBack", -4);
                  break;
              }
              switch (this.$route.meta.layout) {
                case "administrator":
                  this.$router.push(
                    "/applications/" +
                      this.partner_id +
                      "/category/" +
                      this.$route.params.catID +
                      "/dish/" +
                      this.$route.params.dishID
                  );
                  break;
                case "manager":
                  this.$router.push(
                    "/menu/category/" +
                      this.$route.params.catID +
                      "/dish/" +
                      this.$route.params.dishID
                  );
                  break;
              }
              this.$root.$emit("createAlertGood");
              break;
            default:
              this.data = {};
              this.$root.$emit("createAlertError");
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete(this.form);
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    goTo: function(link) {
      this.$router.push(link);
    },

    async get_application_settings() {
      await ApplicationApi.get_settings(this.partner_id)
        .then((response) => {
          switch (response.data.status) {
            case 200:
              this.application_settings = {
                available_branch_stop: response.data.data.available_branch_stop,
              };
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_branches() {
      ApplicationBranchesApi.partnerBranches({ id: this.partner_id })
        .then((response) => {
          console.log(response);
          switch (response.data.status) {
            case 200:
              this.application_branches = response.data.data;
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeBranchStop(data){
      let branchStop = [];
      for (let i in data) {
        if (data.hasOwnProperty(i)) {
          branchStop.push({branch_id: Number(i), status: data[i].status, modifier_id: this.form.id })
        }
      }
      this.form.branch_stop = branchStop;
      this.update_branch_stop_data();
    },
    update_branch_stop_data(){
      // DishApi.edit_branch_stop(this.form.branch_stop, this.form.id)
      //     .then((response) => {
      //       switch (response.data.status) {
      //         case 200:
      //           this.$root.$emit("createAlertGood");
      //           break;
      //         default:
      //           this.$root.$emit("createAlertError");
      //           break;
      //       }
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //     });
    }
  },

  async mounted() {
    if (this.$route.params.modifierID !== undefined) {
      await this.get(this.$route.params.modifierID);
      await this.get_application_settings();

      if (this.application_settings.available_branch_stop) {
        this.get_branches();
      }
    }
  },

  created() {
    this.form.id = this.$route.params.modifierID;
    this.form.modifier_group_id = this.$route.params.modifierGroupID;

    if (this.$route.params.modifierID !== undefined) {
      this.get(this.$route.params.modifierID);
    }
  },
};
</script>

<style lang="scss">
.ant-form-item-label {
  line-height: inherit !important;
}
.ant-form-item {
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary,
.buttons_container .ant-btn-danger {
  width: 100% !important;
  margin: 0 !important;
  & span {
    color: #fff;
  }
}
</style>

<style scoped>
.content_columns {
  margin-top: 25px;
}
.create {
  margin: 4px;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  width: 100%;
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
</style>
