<template>
  <div>
    <wrapper_navigation
        element-header="Функции системы"
        description=""
        @click.native="open_page('functions')"
    />
    <wrapper_navigation
        element-header="Версии мобильного приложения"
        description=""
        @click.native="open_page('mobile-app-versions')"
    />

  </div>
</template>

<script>
import wrapper_navigation from '@/components/elements/wrapper_navigation'

export default {
  components: {
    wrapper_navigation
  },
  data(){
    return {

    }
  },
  created() {
    console.log(this.editor);
    this.getSettings();
  },
  methods: {
    open_page: function (pageName){
      this.$router.push(this.$route.path+'/'+pageName);
    },
    getSettings: async function () {

    }
  }
}
</script>