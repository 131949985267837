ч<template>
  <AddEditDish></AddEditDish>
</template>
<script>
import AddEditDish from '../../../../components/Menu/add-edit-dish'
export default {
  components: {
    AddEditDish
  }
}
</script>

