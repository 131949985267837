<template>
  <div>
    <wrapper_navigation
      :element-header="TextLocalization.BasicSettings[language]"
      :description="TextLocalization.BasicSettingsDescription[language]"
      @click.native="open_page('main')"
    />
    <wrapper_navigation
        element-header="Режим работы заведения"
        description=""
        @click.native="open_page('operation-time')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.BranchesOfTheOrganization[language]"
        :description="TextLocalization.BranchesOfTheOrganizationDescription[language]"
        @click.native="open_page('branch')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.OrderingMethods[language]"
        :description="TextLocalization.OrderingMethodsDescription[language]"
        @click.native="open_page('ways-of-ordering')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.FormsOfPayment[language]"
        description="Предоставляйте пользователю максимальное количество вариантов способа оплаты для увеличения количества заказов"
        @click.native="open_page('payment-methods')"
    />
    <wrapper_navigation
        v-show="form.take_delivery_orders === true"
        :element-header="TextLocalization.DeliveryTerms[language]"
        description="Редактирование условий минимального заказа и стоимости доставки."
        @click.native="open_page('delivery-terms')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.SocialNetworks[language]"
        description="Добавить/изменить ссылки на основные социальные сети ресторана."
        @click.native="open_page('social-media')"
    />
    <wrapper_navigation
        :element-header="TextLocalization.OrderTransferMethods[language]"
        description="Выберете способ информирования о новых заказах."
        @click.native="open_page('order-transfer-methods')"
    />
    <wrapper_navigation
        element-header="Пользователи"
        description=""
        @click.native="open_page('users')" v-if="editor === 1"
    />
<!--    <wrapper_navigation-->
<!--        element-header="API интеграция"-->
<!--        description=""-->
<!--        @click.native="open_page('api')"-->
<!--    />-->
  </div>
</template>

<script>
import FormPartnerID from '@/mixins/form-partnerID';
import ApplicationApi from "../../api/ApplicationApi";
import wrapper_navigation from '@/components/elements/wrapper_navigation'

export default {
  mixins: [FormPartnerID],
  components: {
    wrapper_navigation
  },
  data(){
    return {
      editor: null,
      form: {
        partner_id: null,
        take_delivery_orders: undefined
      }
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
    open_page: function (pageName){
      this.$router.push(this.$route.path+'/'+pageName);
    },
    getSettings: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      this.form = {
        partner_id: settings.partner_id,
        take_delivery_orders: settings.take_delivery_orders
      };
    }
  }
}
</script>