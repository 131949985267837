<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.partner_id" />
      <a-form-model-item :label="TextLocalization.CategoryName[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item :label="'Внешний id'">
        <a-input v-model="form.outside_id" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Image[language]">
        <input
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />
        <p>Рекомендуемый размер картинки ширина: 656px, высота: 388px</p>
        <img :src="form.icon_src" style="width: 30px;" />
      </a-form-model-item>
    </a-col>

    <a-col :span="24" :md="24">
      <div class="personal_fields">
        <div class="name">
          Персонафицированные поля в категории:
        </div>
        <div
          v-for="(data, dataIndex) in form.additional_properties_type.data"
          :key="dataIndex"
          class="additional_properties__wrapper"
        >
          <a-form-model-item label="Название">
            <a-input v-model="data.name" />
          </a-form-model-item>
          <a-form-model-item label="Alias">
            <a-input v-model="data.alias" />
          </a-form-model-item>
          <a-form-model-item label="Тип">
            <a-select v-model="data.type">
              <a-select-option value="Numeric">Числовое поле</a-select-option>
              <a-select-option value="Boolean">Да/Нет</a-select-option>
              <a-select-option value="Variants"
                >Варианты ответа</a-select-option
              >
              <a-select-option value="Any">Любое</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Отображать в карточке товара">
            <a-switch v-model="data.show_in_goods_card" />
          </a-form-model-item>
          <a-form-model-item label="Отображать в качестве фильтра">
            <a-switch v-model="data.show_like_filter" />
          </a-form-model-item>
          <a-form-model-item label=" ">
            <a-button
              type="danger"
              @click="delete_personal_field(dataIndex)"
              style="width: 100%"
            >
              {{ TextLocalization.Delete[language] }}
            </a-button>
          </a-form-model-item>
        </div>

        <div class="add" @click="add_personal_field">Добавить поле</div>
      </div>

      <a-form-model-item label="Нет в наличии">
        <div>
          <a-switch v-model="form.in_stop_list" />
        </div>
      </a-form-model-item>
    </a-col>
    <a-col
      :span="24"
      :md="24"
      v-if="
        this.$route.params.catID !== undefined &&
          this.application_settings.available_branch_stop === true
      "
    >
      <stop_branch
          v-show="!form.in_stop_list"
          header="Доступно на филиалах:"
          :application_branches="application_branches"
          :statuses = "form.branch_stop"
          @changeData="changeBranchStop"
      />
    </a-col>

    <a-col :span="24" :md="24">
      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="this.$route.params.catID === undefined ? 24 : 12">
            <a-button type="primary" @click="onSubmit">
              {{
                this.$route.params.catID === undefined
                  ? this.TextLocalization.Add[language]
                  : TextLocalization.Edit[language]
              }}
            </a-button>
          </a-col>
          <a-col :span="12">
            <a-button
              type="danger"
              @click="showModalDelete"
              v-if="this.$route.params.catID !== undefined"
            >
              {{ TextLocalization.Delete[language] }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>

    <a-col :span="24" :md="16" v-if="this.$route.params.catID !== undefined">
      <a-form-model-item label="Вложенные товары" class="mt-20">
        <div>
          <div class="nested_goods__in_category">
            <div
              class="nested_goods__in_category__good"
              v-for="(dish, dishIndex) in form.dishes"
              :key="dishIndex"
              @click="open_dish(dish.id)"
            >
              <h6>
                {{ dish.name }}
                <span class="edit"
                  >({{ TextLocalization.Edit[language] }})</span
                >
              </h6>
            </div>
            <div
              class="create_category"
              @click="open_dish('add?parent_category=' + form.id)"
            >
              {{ TextLocalization.AddDish[language] }}
            </div>
          </div>
        </div>
      </a-form-model-item>
    </a-col>

    <a-col
      :span="24"
      :md="8"
      v-if="this.$route.params.catID !== undefined"
      class="mt-20"
    >
      <a-form-model-item label="Вложенные категории">
        <div>
          <div class="nested_goods__in_category">
            <div
              class="nested_goods__in_category__good"
              v-for="(category, catIndex) in form.categories"
              :key="catIndex"
              @click="open_category(category.id)"
            >
              <h6>
                {{ category.name }}
                <span class="edit"
                  >({{ TextLocalization.Edit[language] }})</span
                >
              </h6>
            </div>
            <div
              class="create_category"
              @click="open_category('add?parent_category=' + form.id)"
            >
              {{ TextLocalization.AddCategory[language] }}
            </div>
          </div>
        </div>
      </a-form-model-item>
    </a-col>

    <a-modal
      :title="TextLocalization.AreYouSure[language]"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>
import MenuAPI from "../../../api/MenuAPI";
import FormPartnerID from "@/mixins/form-partnerID";
import axios from "axios";
import ApplicationApi from "../../../api/ApplicationApi";
import ApplicationBranchesApi from "../../../api/ApplicationBranchesApi";
import stop_branch from "./stop_branch";

export default {
  mixins: [FormPartnerID],
  components: {
    stop_branch,
  },
  data() {
    return {
      application_settings: null,
      visible: false,
      confirmLoading: false,
      file: null,
      application_branches: [],
      available_on_branches: [],
      form: {
        branch_stop: undefined,
        partner_id: null,
        name: "",
        description: "",
        outside_id: "",
        in_stop_list: false,
        additional_properties_type: {
          data: [],
        },
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$route.params.catID === undefined) {
        this.add_category(this.form);
      } else {
        this.edit_category(this.form);
      }
    },
    get_data(category_id) {
      MenuAPI.get_category(category_id)
        .then((response) => {
          switch (response.data.status) {
            case 200:
              console.log(response.data.data);
              this.form = response.data.data;
              console.log(this.form);
              break;
            default:
              this.data = {
                name: "",
                description: "",
              };
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async edit_category(data) {
      if (this.file) {
        let formData = new FormData();
        let img_src = null;
        formData.append("myFile", this.file);
        formData.append("type", "icon");

        await axios
          .put("https://img.gastrosoft.by/upload/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(result) {
            img_src = result.data.src;
          })
          .catch(function(e) {
            console.log(e);
          });
        data.icon_src = "https://img.gastrosoft.by/img/icons/300/" + img_src;
      }

      MenuAPI.edit_category(data)
        .then((response) => {
          response.status === 401 ? this.$store.commit("User/EXIT_USER") : null;
          switch (response.data.status) {
            case 200:
              this.form = response.data.data;
              console.log(this.form);
              this.$root.$emit("createAlertGood");
              break;
            default:
              this.data = {
                name: "",
                description: "",
              };
              this.$root.$emit("createAlertError");
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async add_category(data) {
      if (this.$route.query.parent_category) {
        data.parent_id = Number(this.$route.query.parent_category);
      }

      if (this.file) {
        let formData = new FormData();
        let img_src = null;
        formData.append("myFile", this.file);
        formData.append("type", "icon");

        await axios
          .put("https://img.gastrosoft.by/upload/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(result) {
            img_src = result.data.src;
          })
          .catch(function(e) {
            console.log(e);
          });
        data.icon_src = "https://img.gastrosoft.by/img/icons/300/" + img_src;
      }

      MenuAPI.add_category(data)
        .then((response) => {
          response.status === 401 ? this.$store.commit("User/EXIT_USER") : null;
          switch (response.data.status) {
            case 200:
              this.form = response.data.data;
              this.$store.dispatch("BackButton/setGoBack", -2);
              switch (this.editor) {
                case 0:
                  this.$router.push(
                    "/applications/" +
                      data.partner_id +
                      "/category/" +
                      response.data.data.id
                  );
                  break;
                case 1:
                  this.$router.push("/menu/category/" + response.data.data.id);
                  break;
              }
              this.$root.$emit("createAlertGood");
              break;
            default:
              this.data = {
                name: "",
                description: "",
              };
              this.$root.$emit("createAlertError");
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    open_category: function(id) {
      switch (this.$route.meta.layout) {
        case "administrator":
          this.goTo(
            "/applications/" + this.form.partner_id + "/category/" + id
          );
          break;
        case "manager":
          this.goTo("/menu/category/" + id);
          break;
      }
    },
    goTo: function(data) {
      window.location.href = data;
    },
    delete(data) {
      MenuAPI.delete_category(data)
        .then((response) => {
          response.status === 401 ? this.$store.commit("User/EXIT_USER") : null;
          switch (response.data.status) {
            case 200:
              switch (this.$store.getters["BackButton/goBack"]) {
                case -1:
                  this.$store.dispatch("BackButton/setGoBack", -3);
                  break;
                case -2:
                  this.$store.dispatch("BackButton/setGoBack", -4);
                  break;
              }
              switch (this.editor) {
                case 0:
                  this.$router.push(
                    "/applications/" + this.form.partner_id + "/Menu"
                  );
                  break;
                case 1:
                  this.$router.push("/menu");
                  break;
              }
              break;
            default:
              this.data = {};
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showModalDelete() {
      this.visible = true;
    },
    add_personal_field: function() {
      this.form.additional_properties_type.data.push({});
    },
    delete_personal_field: function(key) {
      this.form.additional_properties_type.data.splice(key, 1);
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({ id: this.form.id });
      this.visible = false;
      this.confirmLoading = false;
    },
    open_dish: function(id) {
      let catID = this.form.id;
      switch (this.$route.meta.layout) {
        case "administrator":
          this.goTo(
            "/applications/" +
              this.$route.params.id +
              "/category/" +
              catID +
              "/dish/" +
              id
          );
          break;
        case "manager":
          this.goTo("/menu/category/" + catID + "/dish/" + id);
          break;
      }
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    async get_application_settings() {
      await ApplicationApi.get_settings(this.form.partner_id)
        .then((response) => {
          switch (response.data.status) {
            case 200:
              this.application_settings = {
                available_branch_stop: response.data.data.available_branch_stop,
              };
              break;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_branches() {
      ApplicationBranchesApi.partnerBranches({ id: this.form.partner_id })
        .then((response) => {
          this.application_branches = response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeBranchStop(data){
      let branchStop = [];
      for (let i in data) {
        if (data.hasOwnProperty(i)) {
          branchStop.push({branch_id: Number(i), status: data[i].status, category_id: this.form.id })
        }
      }
      this.form.branch_stop = branchStop;
      this.update_branch_stop_data();
    },
    update_branch_stop_data(){
      MenuAPI.edit_branch_stop_category(this.form.branch_stop, this.form.id)
          .then((response) => {
            switch (response.data.status) {
              case 200:
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.$root.$emit("createAlertError");
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    }
  },
  async mounted() {
    if (this.$route.params.catID !== undefined) {
      this.get_data(this.$route.params.catID);
      await this.get_application_settings();

      if (this.application_settings.available_branch_stop) {
        this.get_branches();
      }
    }
  },
};
</script>

<style lang="scss">
.ant-form-item-label {
  line-height: inherit !important;
}
.ant-form-item {
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary,
.buttons_container .ant-btn-danger {
  width: 100% !important;
  margin: 0 !important;
}
.menu_category,
.create_category {
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  width: 100%;
  padding: 10px;
  position: relative;
}
.create_category {
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  line-height: initial;
  &:hover {
    background-color: rgba(24, 144, 255, 0.15);
    border: 1px dashed #1890ff;
    text-decoration: underline;
  }
}
.menu_category {
  &:hover {
    border: 1px dashed #1890ff;
    background-color: rgba(24, 144, 255, 0.15);
    cursor: pointer;
  }
  & h6 {
    margin-bottom: 10px;
    margin-left: 20px;

    & .edit {
      font-size: 80%;
      font-weight: initial;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.nested_goods__in_category {
  //display:
  display: grid;
  width: 100%;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  & .nested_goods__in_category__good {
    padding: 12px;
    display: block;
    cursor: pointer;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    &:hover {
      background-color: rgba(24, 144, 255, 0.15);
      border: 1px dashed #1890ff;
      text-decoration: underline;
    }
    & h6 {
      font-size: 95%;
      line-height: initial;
      & .edit {
        font-size: 80%;
        font-weight: initial;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .nested_goods__in_category {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
  }
}
.personal_fields {
  margin: 15px 0;
  padding: 15px;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  color: #000;
  & .name {
    font-weight: bold;
    font-size: 80%;
    margin-bottom: 10px;
  }
  & > .add {
    padding: 12px;
    display: block;
    cursor: pointer;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    background-color: rgba(24, 144, 255, 0.35);
    font-weight: 600;
    font-size: 12px;
    &:hover {
      background-color: rgba(24, 144, 255, 0.5);
    }
  }
}
.additional_properties__wrapper {
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1;
    margin: 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
