<template>
  <div>
    <a-row type="flex" :gutter="24">
      <a-col :span="24" :md='12' :lg="8">
        <a-form-model :model="form">
          <a-form-model-item label="Название приложения">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item label="API key" v-if="this.$route.params.id !== undefined">
            <a-input v-model="form.token" readOnly />
          </a-form-model-item>
          <a-form-model-item label="Баланс" v-if="this.$route.params.id !== undefined">
            <a-input v-model="form.balance" readOnly />
          </a-form-model-item>

          <a-form-model-item label="Версия приложения">
            <a-select v-model="form.application_version">
              <a-select-option  v-for="(version,versionKey) in applicationVersionsAvailable" :value="version.id" :key="versionKey">
                {{ version.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="Тариф">
            <a-select v-model="form.application_tariff">
              <a-select-option  v-for="(version,versionKey) in applicationTariffsAvailable" :value="version.id" :key="versionKey">
                {{ version.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="Условия работы">
            <a-select default-value="1" v-model="form.work_model">
              <a-select-option value="1">
                Процент от оборота
              </a-select-option>
              <a-select-option value="2">
                Абонентская плата
              </a-select-option>
              <a-select-option value="3">
                Полный выкуп
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <a-form-model-item label="Процент" v-if="form.work_model === '1'">
          <a-input v-model="form.payment_percent" type="number" step="0.01" />
        </a-form-model-item>
        <a-form-model-item label="Тариф" v-if="form.work_model === '2'">
          <a-input v-model="form.payment_tarif" />
        </a-form-model-item>
        <a-form-model-item label="Стоимость" v-if="form.work_model === '3'">
          <a-input v-model="form.payment_amount_once" />
        </a-form-model-item>
        <a-form-model-item label="Ссылка на App Store">
          <a-input v-model="form.marketing_appstore_link" />
        </a-form-model-item>
        <a-form-model-item label="Ссылка на Google Play">
          <a-input v-model="form.marketing_google_play_link" />
        </a-form-model-item>
        <a-form-model-item label="Ссылка на веб-сайт">
          <a-input v-model="form.marketing_website_link" />
        </a-form-model-item>
        <a-form-model-item label="Иконка мобильного приложения">
          <div style="display: flex;">
            <div>
              <input type="file" id="file" ref="file" v-on:change="handleFileUpload()">
            </div>
            <div>
              <img :src="form.icon_src" style="max-height: 75px">
            </div>
          </div>


        </a-form-model-item>

      </a-col>
      <a-col :span="24" :md='12' :lg="8">
        <a-form-model-item label="Юр. наименование">
          <a-input v-model="form.business_name" />
        </a-form-model-item>
        <a-form-model-item label="УНП">
          <a-input v-model="form.business_id" />
        </a-form-model-item>
        <a-form-model-item label="Номер счета IBAN">
          <a-input v-model="form.iban" />
        </a-form-model-item>
        <a-form-model-item label="BIC">
          <a-input v-model="form.business_bic" />
        </a-form-model-item>
        <a-form-model-item label="Банк">
          <a-input v-model="form.bank" />
        </a-form-model-item>
        <a-form-model-item label="Юр. адрес">
          <a-input v-model="form.business_address" />
        </a-form-model-item>
        <a-form-model-item label="Почтовый адрес">
          <a-input v-model="form.post_address" />
        </a-form-model-item>
        <a-form-model-item label="Дата акцептирования">
          <a-input v-model="form.contract_accept_date" type="date" />
        </a-form-model-item>
        <a-form-model-item label="Дата окончания грейс периода">
          <a-input v-model="form.grace_period_end_date" type="date" />
        </a-form-model-item>
      </a-col>
      <a-col :span="24" :lg="8">
        <a-form-model-item class="buttons_container">
          <a-button type="primary" @click="onSubmit">
            {{this.$route.params.id === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
          </a-button>
        </a-form-model-item>

        <a-form-model-item v-if="this.$route.params.id !== undefined" class="buttons_container">
          <a-button type="danger" @click="showModalDelete" style="width: 100%">
            {{ TextLocalization.Delete[language] }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item v-if="this.$route.params.id !== undefined " class="buttons_container">
          <a-button type="warning" @click="goTo('/applications/'+$route.params.id+'/finance')">
            {{ TextLocalization.FinancialReport[language] }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item v-if="this.$route.params.id !== undefined " class="buttons_container">
          <a-button type="warning" @click="goTo('/applications/'+$route.params.id+'/marketing')">
            {{ TextLocalization.Marketing[language] }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item v-if="this.$route.params.id !== undefined " class="buttons_container">
          <a-button type="warning" @click="goToMenu">
            {{ TextLocalization.Menu[language] }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item v-if="this.$route.params.id !== undefined " class="buttons_container">
          <a-button type="warning" @click="goTo('/applications/'+$route.params.id+'/settings')">
            {{ TextLocalization.Settings[language] }}
          </a-button>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row type="flex" :gutter="24" v-if="this.$route.params.id !== undefined ">
      <a-col :span="24" :md='12' :lg="8">
        <div>
          <h6>{{ TextLocalization.Users[language] }}</h6>
          <div class="info" v-for="(user, userKey) in this.users"  @click="goTo('/applications/'+$route.params.id+'/user/'+user.id)">
            <b>{{ TextLocalization.Login[language] }}:</b> {{user.login}}<br>
            <b>{{ TextLocalization.FullName[language] }}:</b> {{user.surname}} {{user.name}} {{user.lastname}}<br>
            <span v-if="user.email !== null && user.email !== ''" ><b>Email:</b> {{user.email}}<br></span>
            <span v-if="user.phone !== null && user.phone !== ''" ><b>{{ TextLocalization.PhoneNumber[language] }}:</b> {{user.phone}}<br></span>
          </div>
          <div class="create"  @click="goTo('/applications/'+$route.params.id+'/user/add')">
            {{ TextLocalization.AddUser[language] }}
          </div>
        </div>
      </a-col>
      <a-col :span="24" :md='12' :lg="8">
        <div>
          <h6>{{ TextLocalization.ContactsInTheOrganization[language]}}</h6>
          <div class="info" v-for="(contact, contactKey) in this.contacts" @click="goTo('/applications/'+$route.params.id+'/contact/'+contact.id)">
            <b>{{ TextLocalization.FullName[language] }}:</b> {{contact.surname}} {{contact.name}} {{contact.lastname}}<br>
            <b>{{ TextLocalization.Position[language] }}:</b> {{contact.position}}<br>
            <span v-if="contact.email !== null && contact.email !== ''" ><b>Email:</b> {{contact.email}}<br></span>
            <span v-if="contact.phone1 !== null && contact.phone1 !== ''" ><b>{{ TextLocalization.PhoneNumber[language] }}:</b> {{contact.phone1}}<br></span>
            <span v-if="contact.phone2 !== null && contact.phone2 !== ''" ><b>{{ TextLocalization.PhoneNumber[language] }}:</b> {{contact.phone2}}<br></span>
            <span v-if="contact.phone3 !== null && contact.phone3 !== ''" ><b>{{ TextLocalization.PhoneNumber[language] }}:</b> {{contact.phone3}}<br></span>
          </div>
          <div class="create" @click="goTo('/applications/'+$route.params.id+'/contact/add')">
            {{ TextLocalization.AddContact[language] }}
          </div>
        </div>
      </a-col>
      <a-col :span="24" :md='12' :lg="8">
        <div>
          <h6>{{ TextLocalization.BranchesOfTheOrganization[language] }}:</h6>
          <div class="info" v-for="(branch, branchKey) in this.branches" @click="goTo('/applications/'+$route.params.id+'/settings//branch/'+branch.id)">
            <b>{{ TextLocalization.Address[language] }}:</b> {{branch.address}}<br>
          </div>
          <div class="create" @click="goTo('/applications/'+$route.params.id+'/settings/branch/add')">
            {{ TextLocalization.AddBranch[language] }}
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </div>

</template>
<script>
import ApplicationApi from "../../../../api/ApplicationApi";
import DevelopmentAPI from "../../../../api/DevelopmentAPI";
import UploadAvatar from "../../../components/elements/upload_avatar"
import moment from 'moment';
import axios from "axios";

export default {
  components:{
    UploadAvatar
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      applicationVersionsAvailable: [],
      applicationTariffsAvailable: [],
      form: {
        name: '',
        business_name: '',
        business_id: '',
        iban: '',
        bank: '',
        business_address:'',
        business_bic: '',
        post_address: '',
        token: '',
        balance: '',
        work_model: null,
        payment_tarif: null,
        payment_amount_once: null,
        payment_percent: null,
        marketing_google_play_link: '',
        marketing_website_link: '',
        marketing_appstore_link: '',
        contract_accept_date: null,
        grace_period_end_date: null
      },
      users: [],
      branches: [],
      contacts: []
    };
  },
  methods: {
    goToMenu(){
      this.$router.push('/applications/'+this.$route.params.id+'/menu');
    },
    onSubmit() {
      let id = this.$route.params.id;
      delete this.form.token;
      delete this.form.balance;

      if (id === undefined){
        this.add_partner(this.form);
      } else {
        this.edit_partner(this.form);
      }
    },
    get_partner_data(partner_id){
      ApplicationApi.get_data(partner_id)
          .then(response => {
            switch (response.data.status){
              case 200:
                let payment_percent = response.data.data.payment_percent
                delete response.data.data.payment_percent;
                this.form = response.data.data;

                if(payment_percent){
                  this.form.payment_percent = payment_percent * 100;
                }


                this.form.contract_accept_date = moment(this.form.contract_accept_date).format('yyyy-MM-DD');
                this.form.grace_period_end_date = moment(this.form.grace_period_end_date).format('yyyy-MM-DD');

                this.users = response.data.users;
                this.branches = response.data.branch;
                this.contacts = response.data.contacts;

                this.form.work_model = (this.form.work_model).toString();
                this.form.order_transfer = (this.form.order_transfer).toString();
                this.form.balance = this.formatter(response.data.data.balance);
                break;
              default:
                this.data = {
                  name: '',
                  business_name: '',
                  business_id: '',
                  iban: '',
                  bank: '',
                  business_address:'',
                  post_address: '',
                  token: '',
                  balance: '',
                  order_transfer: 0
                };
                this.$router.push('/applications/');
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    async edit_partner(data){

      if(this.file) {
        let formData = new FormData();
        let img_src = null;
        formData.append('myFile', this.file);
        formData.append('type', 'icon');

        await axios.put( 'https://img.gastrosoft.by/upload/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(result){
          img_src = result.data.src;
        }).catch(function(e){
          console.log(e);
        });
        data.icon_src = 'https://img.gastrosoft.by/img/icons/300/' + img_src;
      }

      if(data.payment_percent){
        data.payment_percent = Number(data.payment_percent) / 100;
      }

      ApplicationApi.edit_data(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                let payment_percent = response.data.data.payment_percent
                delete response.data.data.payment_percent;
                this.form = response.data.data;

                if(payment_percent){
                  this.form.payment_percent = payment_percent * 100;
                }

                this.form.contract_accept_date = moment(this.form.contract_accept_date).format('yyyy-MM-DD');
                this.form.grace_period_end_date = moment(this.form.grace_period_end_date).format('yyyy-MM-DD');

                this.form.work_model = (this.form.work_model).toString();
                this.form.order_transfer = (this.form.order_transfer).toString();
                this.form.balance = this.formatter(response.data.data.balance);
                this.$root.$emit('createAlertGood');
                break;
              default:
                this.$root.$emit('createAlertError');
                this.data = {
                  name: '',
                  business_name: '',
                  business_id: '',
                  iban: '',
                  bank: '',
                  business_address:'',
                  post_address: '',
                  token: '',
                  balance: '',
                  work_model: null,
                  payment_tarif: null,
                  payment_amount_once: null,
                  payment_percent: null
                };
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    add_partner(data){
      ApplicationApi.add_data(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;

                if(this.form.work_model != null){
                  this.form.work_model = (this.form.work_model).toString();
                }
                if(this.form.order_transfer != null){
                  this.form.order_transfer = (this.form.order_transfer).toString();
                }

                this.form.balance = this.form.balance + ' BYN'
                this.$store.dispatch('BackButton/setGoBack', -2);
                this.$router.push('/applications/'+response.data.data.id);
                this.$root.$emit('createAlertGood');
                break;
              default:
                this.$root.$emit('createAlertError');
                this.data = {
                  name: '',
                  business_name: '',
                  business_id: '',
                  business_bic: '',
                  iban: '',
                  bank: '',
                  business_address:'',
                  post_address: '',
                  token: '',
                  balance: '',
                  work_model: null,
                  payment_tarif: null,
                  payment_amount_once: null,
                  order_transfer: 0
                };
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete(data){
      ApplicationApi.delete(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.goTo('/applications/');
                this.$root.$emit('createAlertGood');
                break;
              default:
                this.$root.$emit('createAlertError');
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    goTo(link){
      this.$router.push(link);
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    },
    getApplicationVersionsAvailable(){
      DevelopmentAPI.get_app_versions()
          .then(response => {
            this.applicationVersionsAvailable = response.data.data
          })
    },
    getApplicationTariffsAvailable() {
      ApplicationApi.getApplicationTariffsAvailable()
          .then(response => {
            this.applicationTariffsAvailable = response.data.data
          })
    }
  },
  mounted(){
    if (this.$route.params.id !== undefined){
      this.get_partner_data(this.$route.params.id);
      this.getApplicationVersionsAvailable();
      this.getApplicationTariffsAvailable();
    }
  }
};
</script>

<style lang="scss">
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn{
  width: 100%!important;
  margin: 0!important;
}
</style>

<style scoped lang="scss">
.create, .info{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  font-size: 80%;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  padding: 15px 10px;
  font-weight: bold;
  transition: border-color 0.3s ease;
  margin-top: 15px;

}
.info{
  &:hover{
    background-color: rgba(24, 144, 255, 0.15);
    border: 1px dashed #1890FF;
  }
}
.create{
  text-align: center;
  &:hover{
    background-color: rgba(24, 144, 255, 0.15);
    color: #1890FF;
    border: 1px dashed #1890FF;
    text-decoration: underline;
  }
}
.info{
  color: #000;
  line-height: 1.5;
  font-weight: initial;
}
h6{
  margin-top: 25px;
}
</style>
