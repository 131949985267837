import BaseApi from "./BaseApi";

class FinanceAPI extends BaseApi {

    addPayment(data) {
        return this.axios.post('/finance/add-payment', data);
    }

    addPromisedPayment(data) {
        return this.axios.post('/finance/add-promised-payment', data);
    }

    deletePayment(data) {
        return this.axios.post('/finance/delete-payment', data);
    }

    addCompletedAct(data) {
        return this.axios.post('/finance/add-completed-act', data);
    }

    registerReceivedPayments(data){
        return this.axios.post('/finance/register-received-payments', data);
    }

    registerCompletedWorks(data){
        return this.axios.post('/finance/completed-acts', data);
    }

    get_price_list(){
        return this.axios.get('/finance/price-list');
    }

    get_price_by_id(id){
        return this.axios.get('/finance/price-list/'+ id );
    }

    edit_price(data){
        return this.axios.post('/finance/price-list/edit', data);
    }

    add_price(data){
        return this.axios.post('/finance/price-list/add', data);
    }


}

export default new FinanceAPI();
