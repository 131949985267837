<template>
  <Menu v-if="settings.view_type === 0"></Menu>
  <MenuFormatBig v-else-if="settings.view_type === 1"></MenuFormatBig>
</template>
<script>

  // Принцип формирования меню Landing Page
  import Menu from '../../components/Menu/menu'

  // Принцип формирования страницы Cat-> SubCat -> Good
  import MenuFormatBig from '../../components/Menu/MenuFormatBig'
  import ApplicationApi from "../../../api/ApplicationApi";

  export default {
    data(){
      return {
        settings: null
      }
    },
    created() {
      this.getSettings();
    },
    methods: {
      getSettings: async function () {

        switch (this.user_info.type){
          case 0:
            this.partner_id = this.$route.params.id;
            break;
          case 1:
            this.partner_id = this.user_info.partner_id;
            break;
        }

        let settings = (await ApplicationApi.get_settings(this.partner_id)).data.data;

        this.settings = {
          id: settings.id,
          view_type: settings.view_type,
        };
      }
    },
    components: {
      Menu,
      MenuFormatBig
    }
  }
</script>

