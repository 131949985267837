import * as Cookie from 'js-cookie';
import jwt_decode from "jwt-decode";

const state = () => ({
  id: null,
  name: null,
  surname: null,
  lastname: null,
  authStatus: false,
  authToken: null,
  role: null,
  type: null,
  position: null,
  partner_id: null,
  application_version: null,
  language: ''
});

const getters = {
  getUserId(state) {
    return state.id;
  },

  getUserAuthStatus(state) {
    return state.authStatus;
  },

  getUserLanguage(state){
    return (state.language === '' ? 'RU' : state.language);
  },

  getUserAuthToken(state) {
    return state.authToken;
  },

  getUserInfo(state) {
    return {
      id: state.id,
      name: state.name,
      surname: state.surname,
      lastname: state.lastname,
      role: state.role,
      position: state.position,
      application_version: state.application_version,
      type: state.type,
      partner_id: state.partner_id
    };
  },

};

const mutations = {
  SET_USER_DATA(state, payload) {
    state.id = payload.id;
    state.name = payload.name;
    state.surname = payload.surname;
    state.lastname = payload.lastname;
    state.role = payload.role;
    state.position = payload.position;
    state.type = payload.type;
    state.partner_id = payload.partner_id;
    state.application_version = payload.application_version;
    state.language = 'RU'
  },
  SET_USER_LANGUAGE(state, payload){
    state.language = payload;
  },
  SET_USER_AUTH_TOKEN(state, payload) {
    state.authStatus = true;
    state.authToken = payload;
  },
  EXIT_USER(state, payload) {
    state.id = null;
    state.name = null;
    state.surname = null;
    state.application_version = null;
    state.lastname = null;
    state.position = null;
    state.authStatus = false;
    state.authToken = null;
    state.partner_id = null;
  },
};

const actions = {
  changeTakeOrdersStatus({ commit }, status) {
    commit('SET_USER_TAKE_ORDERS_STATUS', status);
  },
  changeLanguage({commit}, lang){
      commit('SET_USER_LANGUAGE', lang);
  },
  async setAuthToken({ commit }, token) {
    await Cookie.set('token', token);
    let decoded_token = jwt_decode(token);

    commit('SET_USER_AUTH_TOKEN', token);
    commit('SET_USER_DATA', decoded_token)
  },
  exit({ commit }, status) {
    commit('EXIT_USER', status);
    Cookie.set('token', null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
