import BaseApi from "./BaseApi";

class UserApi extends BaseApi {

  refreshData(){
    super.refreshData();
  }

  authUser (data) {
    return this.axios.post('/auth?'+new Date().getTime(), data);
  }

  getAllSystemUsers () {
    return this.axios.get('/users/get-system-users');
  }

  getSystemUser (id) {
    return this.axios.get('/users/get-system-user/'+id);
  }

  addSystemUser (data) {
    return this.axios.post('/users/add-system-user', data);
  }

  editSystemUser (data) {
    return this.axios.post('/users/edit-system-user', data);
  }

  deleteSystemUser (data) {
    return this.axios.post('/users/delete-system-user', data);
  }

  getAllRestaurantUsers (id) {
    return this.axios.get('/users/get-restaurant-users/'+id);
  }

  getRestaurantUser (id) {
    return this.axios.get('/users/get-restaurant-user/'+id);
  }

  addRestaurantUser (data) {
    return this.axios.post('/users/add-restaurant-user', data);
  }

  editRestaurantUser (data) {
    return this.axios.post('/users/edit-restaurant-user', data);
  }

  deleteRestaurantUser (data) {
    return this.axios.post('/users/delete-restaurant-user', data);
  }

  restorePassword(email) {
    return this.axios.get('/users/forgot/?action=send-mail&email='+email);
  }
}

export default new UserApi();
