<template>
  <div class="filials">
    <h6>{{ header }}</h6>
    <div
      v-for="(branch, branchKey) in application_branches"
      :key="branchKey"
      v-if="form[(branch.id)] !== undefined"
    >
      <span>{{ branch.address }}</span>
      <a-switch v-model="form[(branch.id)].status" @change="flagChanged"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ["application_branches", "header", "statuses"],
  data(){
    return {
      form: {}
    }
  },
  watch: {
    statuses: {
      handler(val, oldVal) {
        let data = {}
        val.forEach(function (value){
          data[value.branch_id] = {status: value.status};
        });

        this.form = data;
      }
    }
  },
  methods: {
    flagChanged(){
      this.$emit("changeData", this.form);
    }
  }
};
</script>

<style scoped lang="scss">
.filials {
  margin: 15px 0;
  padding: 15px;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  color: #000;
}

.filials div {
  background-color: #ffffff;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  margin: 10px 0;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
