<template>
  <div>
    <MarketingIndexPartner v-if="this.showBlock === 'partner'"></MarketingIndexPartner>
    <MarketingIndexAdmin v-if="this.showBlock === 'admin'"></MarketingIndexAdmin>
  </div>
</template>

<script>
import MarketingIndexPartner from "@/components/Marketing/index-partner-page/index";
import MarketingIndexAdmin from "@/components/Marketing/index-admin-page/index";

export default {
  data(){
    return{
      showBlock: undefined
    }
  },
  components: {
    MarketingIndexPartner,
    MarketingIndexAdmin
  },
  created() {
    this.user_info = (this.$store.getters["User/getUserInfo"]);
    let id = this.$route.params.id

    if(this.user_info.type === 0){
      if(id === undefined){
        this.showBlock = 'admin'
      }else {
        this.showBlock = 'partner'
      }
    }else {
      this.showBlock = 'partner'
    }
  }
}
</script>