import BaseApi from "./BaseApi";

class DevelopmentAPI extends BaseApi {

    get_app_versions (){
        return this.axios.get('/development/get-versions');
    }

    get_version(id){
        return this.axios.get('/development/get-versions/'+id);
    }

    add_app_version(data){
        return this.axios.post('/development/get-versions/add', data);
    }

    edit_app_version(data){
        return this.axios.post('/development/get-versions/edit', data);
    }

    get_app_functions (){
        return this.axios.get('/development/functions');
    }

    get_function(id){
        return this.axios.get('/development/functions/'+id);
    }

    add_function(data){
        return this.axios.post('/development/functions/add', data);
    }

    edit_function(data){
        return this.axios.post('/development/functions/edit', data);
    }

}

export default new DevelopmentAPI();
