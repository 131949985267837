import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store/index'

import Login from '../views/Login.vue';
import RestorePassword from '../views/RestorePassword.vue';
import RestorePasswordStep1 from '../views/RestorePasswordStep1.vue';
import RestorePasswordStep2 from '../views/RestorePasswordStep2.vue';
import Home from '../views/Home.vue'
import Contacts from '../views/Contacts.vue'
import Menu from '../views/admin-pages/Menu.vue'
import Reports from '../views/admin-pages/Reports';
import Orders from '../views/admin-pages/Orders.vue'
import Settings from '../views/admin-pages/Settings.vue'
import Development from '../views/admin-pages/development';
import Users from '../views/admin-pages/Users.vue'
import Billing from '../views/admin-pages/Billing.vue'
import AddEditCategory from '../views/admin-pages/menu/category/add-edit'
import AddEditCategoryManager from '../views/admin-pages/menu/category/add-edit'
import Applications from '../views/admin-pages/applications';
import ApplicationsEdit from '../views/admin-pages/applications/edit';
import AddEditContact from '../views/admin-pages/application-contacts/edit';
import AddEditBranch from '../views/admin-pages/application-branch/edit';
import AddEditUsers from '../views/admin-pages/application-users/edit';
import FinancePartner from '../views/admin-pages/finance-partner/index';

import Marketing from '../views/admin-pages/Marketing';
import MarketingPush from '../views/admin-pages/marketing/MarketingPush';
import MarketingLoyalty from '../views/admin-pages/marketing/MarketingLoyalty';
import MarketingWidget from '../views/admin-pages/marketing/MarketingWidget';
import MarketingClients from '../views/admin-pages/customers/MarketingClients';
import MarketingTemplatesSpecialOffers from '../views/admin-pages/marketing/MarketingTemplatesSpecialOffers';
import MarketingTemplatesSegments from '../views/admin-pages/marketing/MarketingTemplatesSegments';
import MarketingTemplatesSegmentEdit from '../views/admin-pages/customers/segments/edit';
import MarketingTemplatesAutomaticPush from '../views/admin-pages/marketing/MarketingTemplatesAutomaticPush';
import MarketingTemplatesAutomaticPushCard from '../views/admin-pages/marketing/MarketingTemplatesAutomaticPushCard';

import Legal from '../views/Legal/index';
import LegalPublicContract from '../views/Legal/PublicContract';
import LegalPrivacyPolicy from '../views/Legal/PrivacyPolicy';
import LegalTariffs from '../views/Legal/Tariffs';

import MarketingStatistics from '../views/admin-pages/marketing/MarketingStatistics'
import MarketingPromocode from "@/views/admin-pages/marketing/MarketingPromocode";
import MarketingPromocodeAddEdit from "@/views/admin-pages/marketing/promocode/add-edit";
import MarketingAutoPush from "@/views/admin-pages/marketing/MarketingAutoPush";
import MarketingAutoSMS from "@/views/admin-pages/marketing/MarketingAutoSms";
import MarketingSMS from "@/views/admin-pages/marketing/MarketingSms";
import SegmentAddEdit from '../views/admin-pages/customers/segments/edit';
import SpecialOffers from '../views/admin-pages/marketing/special-offers/index'
import SpecialOffersAddEdit from '../views/admin-pages/marketing/special-offers/edit'

import AddEditDish from '../views/admin-pages/menu/dish/add-edit';
import AddEditSize from '../views/admin-pages/menu/sizes/add-edit';
import AddEditModifierGroup from '../views/admin-pages/menu/modifiers-group/add-edit';
import AddEditModifier from '../views/admin-pages/menu/modifiers/add-edit';
import SpecialOffersManager from '../views/admin-pages/marketing/MarketingSpecialOffers';
import SpecialOffersAddEditManager from '../views/admin-pages/marketing/MarketingSpecialOffers';

import SettingsAPI from '../views/admin-pages/settings/SettingsAPI';
import SettingsOperationTime from '../views/admin-pages/settings/SettingsOperationTime';
import SettingsOrderTransfer from '../views/admin-pages/settings/SettingsOrderTransfer';
import SettingsSocialMedia from '../views/admin-pages/settings/SettingsSocialMedia';
import SettingsDeliveryZone from '../views/admin-pages/settings/SettingsDeliveryZone';
import SettingsBranches from '../views/admin-pages/settings/SettingsBranches'
import SettingsDeliveryTerms from '../views/admin-pages/settings/DeliveryTerms'
import SettingsPaymentMethod from '../views/admin-pages/settings/SettingsPaymentMethod';
import SettingsWaysOrdering from '../views/admin-pages/settings/SettingsWaysOrdering';
import SettingsBasic from '../views/admin-pages/settings/SettingsBasic';

import ReportsAddPayment from '../views/admin-pages/reports/AddPayment';
import ReportsAddCompletedWorkAct from '../views/admin-pages/reports/AddCompletedWorkAct';
import ReportsAddPromisedPayment from '../views/admin-pages/reports/AddPromisedPayment';
import ReportsPriceList from '../views/admin-pages/reports/ReportsPriceList';
import ReportsPriceListCard from '../views/admin-pages/reports/ReportsPriceListCard';
import ReportsTarifs from '../views/admin-pages/reports/ReportsTarifs';
import ReportsDeletePayment from '../views/admin-pages/reports/DeletePayment';
import ReportsComplitedWork from '../views/admin-pages/reports/ComplitedWork';
import ReportsReceivedPayments from '../views/admin-pages/reports/ReceivedPayments';

import DevelopmentMobileAppVersions from '../views/admin-pages/development/mobile-app-versions';
import DevelopmentMobileAppVersionsCard from '../views/admin-pages/development/mobile-app-versions-card';
import DevelopmentFunctions from '../views/admin-pages/development/functions';
import DevelopmentFunctionsCard from  '../views/admin-pages/development/function-card'

let Language = store.getters["User/getUserLanguage"];
import Config from '@/config';

Vue.use(VueRouter);

let routes = [
      {
        // will match everything
        path: '*',
        name: '404',
        layout: "login",
        meta: {
          layoutClass: 'login',
        },
        component: () => import('../views/404.vue'),
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        layout: "login",
        meta: {
          layoutClass: 'login',
          name: Config.TextLocalization.Login[Language],
        },
      },
      {
        path: '/restore-password',
        name: 'RestorePassword',
        component: RestorePassword,
        layout: "login",
        meta: {
            layoutClass: 'RestorePassword',
            name: 'Восстановить пароль',
        },
      },
    {
        path: '/restore-password/step-1',
        name: 'RestorePasswordStep1',
        component: RestorePasswordStep1,
        layout: "login",
        meta: {
            layoutClass: 'RestorePasswordStep1',
            name: 'Восстановить пароль',
        },
    },
  {
    path: '/restore-password/step-2',
    name: 'RestorePasswordStep2',
    component: RestorePasswordStep2,
    layout: "login",
    meta: {
      layoutClass: 'RestorePasswordStep2',
      name: 'Восстановить пароль',
    },
  },
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          layoutClass: 'home',
          name: Config.TextLocalization.Home[Language]
        },
      },
      {
        path: '/applications',
        name: 'Applications',
        component: Applications,
        layout: 'administrator',
        meta: {
          layoutClass: 'Applications',
          name: Config.TextLocalization.Applications[Language],
        },
      },

      {
          path: '/development',
          name: 'Development',
          component: Development,
          layout: 'administrator',
          meta: {
              layoutClass: 'Development',
              name: 'Разработка',
          },
      },

    {
        path: '/development/mobile-app-versions',
        name: 'DevelopmentMobileAppVersions',
        component: DevelopmentMobileAppVersions,
        layout: 'administrator',
        meta: {
            layoutClass: 'Development',
            name: 'Версии мобильного приложения',
        },
    },

    {
        path: '/development/mobile-app-versions/add',
        name: 'DevelopmentMobileAppVersionsCard',
        component: DevelopmentMobileAppVersionsCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'Development',
            name: 'Добавление версии мобильного приложения',
        },
    },
    {
        path: '/development/mobile-app-versions/:id',
        name: 'DevelopmentMobileAppVersionsCard',
        component: DevelopmentMobileAppVersionsCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'Development',
            name: 'Редактирование версии мобильного приложения',
        },
    },


    {
        path: '/development/functions',
        name: 'DevelopmentFunctions',
        component: DevelopmentFunctions,
        layout: 'administrator',
        meta: {
            layoutClass: 'Development',
            name: 'Функции системы',
        },
    },

    {
        path: '/development/functions/add',
        name: 'DevelopmentFunctionsCard',
        component: DevelopmentFunctionsCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'Development',
            name: 'Добавление функции системы',
        },
    },

    {
        path: '/development/functions/:id',
        name: 'DevelopmentFunctionsCard',
        component: DevelopmentFunctionsCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'Development',
            name: 'Редактирование функции системы',
        },
    },



      {
        path: '/bookkeeping',
        name: 'Bookkeeping',
        component: Reports,
        layout: 'administrator',
        meta: {
          layoutClass: 'Reports',
          name: Config.TextLocalization.Bookkeeping[Language],
        },
      },
      {
        path: '/bookkeeping/add-payment',
        name: 'BookkeepingAddPayment',
        component: ReportsAddPayment,
        layout: 'administrator',
        meta: {
          layoutClass: 'Reports',
          name: Config.TextLocalization.TransferFundsToTheAccountOfALegalEntity[Language],
        },
      },
      {
        path: '/bookkeeping/add-promised-payment',
        name: 'BookkeepingAddPromisedPayment',
        component: ReportsAddPromisedPayment,
        layout: 'administrator',
        meta: {
          layoutClass: 'Reports',
          name: Config.TextLocalization.AddThePromisedPaymentToTheBalanceOfTheLegalEntity[Language],
        },
      },
      {
        path: '/bookkeeping/add-completed-work-act',
        name: 'BookkeepingAddCompletedWorkAct',
        component: ReportsAddCompletedWorkAct,
        layout: 'administrator',
        meta: {
          layoutClass: 'Reports',
          name: Config.TextLocalization.AddCompletedAct[Language],
        },
      },

    {
        path: '/bookkeeping/price-list',
        name: 'BookkeepingAPriceList',
        component: ReportsPriceList,
        layout: 'administrator',
        meta: {
            layoutClass: 'Reports',
            name: 'Актуальный прайс лист',
        },
    },
    {
        path: '/bookkeeping/price-list/add',
        name: 'BookkeepingAPriceListAddEdit',
        component: ReportsPriceListCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'Reports',
            name: 'Добавление цены',
        },
    },
    {
        path: '/bookkeeping/price-list/:id',
        name: 'BookkeepingAPriceListAddEdit',
        component: ReportsPriceListCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'Reports',
            name: 'Редактирование цены',
        },
    },

    {
        path: '/bookkeeping/tarifs',
        name: 'BookkeepingAPriceList',
        component: ReportsTarifs,
        layout: 'administrator',
        meta: {
            layoutClass: 'Reports',
            name: 'Актуальные тарифы',
        },
    },

      {
        path: '/bookkeeping/delete-payment',
        name: 'BookkeepingDeletePayment',
        component: ReportsDeletePayment,
        layout: 'administrator',
        meta: {
          layoutClass: 'Reports',
          name: Config.TextLocalization.RemoveErroneousPaymentFromTheBalanceOfALegalEntity[Language],
        },
      },
      {
        path: '/bookkeeping/register-completed-work',
        name: 'BookkeepingRegisterCompletedWork',
        component: ReportsComplitedWork,
        layout: 'administrator',
        meta: {
          layoutClass: 'Reports',
          name: Config.TextLocalization.RegisterOfCompletedActsOfWorkPerformedForThePeriod[Language],
        },
      },
      {
        path: '/bookkeeping/register-received-payments',
        name: 'BookkeepingRegisterReceivedPayments',
        component: ReportsReceivedPayments,
        layout: 'administrator',
        meta: {
          layoutClass: 'Reports',
          name: Config.TextLocalization.RegisterOfPaymentsReceivedForThePeriod[Language],
        },
      },
      {
        path: '/applications/0',
        name: 'Applications0',
        component: ApplicationsEdit,
        layout: 'administrator',
        meta: {
          layoutClass: 'ApplicationsAdd',
          name: Config.TextLocalization.CreateAMobileApp[Language],
        },
      },
      {
        path: '/applications/:id/contact/add',
        name: 'ApplicationsIdContactAdd',
        component: AddEditContact,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditContact',
          name: Config.TextLocalization.AddContact[Language],
        },
      },
      {
        path: '/applications/:id/contact/:contactID',
        name: 'ApplicationsIdContactID',
        component: AddEditContact,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditContact',
          name: Config.TextLocalization.EditContact[Language],
        },
      },
    {
        path: '/applications/:id/settings/branch',
        name: 'SettingsBranches',
        component: SettingsBranches,
        layout: 'administrator',
        meta: {
            layoutClass: 'BranchesOfTheOrganization',
            name: Config.TextLocalization.BranchesOfTheOrganization[Language],
        },
    },
      {
        path: '/applications/:id/settings/branch/add',
        name: 'ApplicationsIdBranchAdd',
        component: AddEditBranch,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditBranch',
          name: Config.TextLocalization.AddBranch[Language],
        },
      },
      {
        path: '/applications/:id/settings/branch/:branchID',
        name: 'ApplicationsIdBranchID',
        component: AddEditBranch,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditBranch',
          name: Config.TextLocalization.EditBranch[Language],
        },
      },
      {
        path: '/applications/:id/user/add',
        name: 'ApplicationsIdUsersAdd',
        layout: 'administrator',
        component: AddEditUsers,
        meta: {
          layoutClass: 'AddEditUsers',
          name: Config.TextLocalization.AddContact[Language],
        },
      },
      {
        path: '/applications/:id/user/:contactID',
        name: 'ApplicationsIdUsersID',
        component: AddEditUsers,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditUsers',
          name: Config.TextLocalization.EditContact[Language],
        },
      },
      {
        path: '/applications/:id',
        name: 'ApplicationsId',
        component: ApplicationsEdit,
        layout: 'administrator',
        meta: {
          layoutClass: 'ApplicationsEdit',
          name: Config.TextLocalization.EditApplication[Language],
        },
      },
      {
        path: '/applications/:id/menu',
        name: 'ApplicationsIdMenu',
        component: Menu,
        layout: 'administrator',
        meta: {
          layoutClass: 'MenuEdit',
          name: Config.TextLocalization.EditRestaurantMenu[Language],
        },
      },
      {
        path: '/applications/:id/settings',
        name: 'ApplicationsIdSettings',
        component: Settings,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.ApplicationSettings[Language],
        },
      },
      {
        path: '/applications/:id/settings/api',
        name: 'ApplicationsIdSettingsApi',
        component: SettingsAPI,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.APIIntegration[Language],
        },
      },
      {
        path: '/applications/:id/settings/order-transfer-methods',
        name: 'ApplicationsIdSettingsOrderTransferMethods',
        component: SettingsOrderTransfer,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.OrderTransferMethods[Language],
        },
      },
      {
        path: '/applications/:id/settings/social-media',
        name: 'ApplicationsIdSettingsSocialMedia',
        component: SettingsSocialMedia,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.SocialNetworks[Language],
        },
      },
      {
        path: '/applications/:id/settings/delivery-zone',
        name: 'ApplicationsIdSettingsDeliveryZone',
        component: SettingsDeliveryZone,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.DeliveryZones[Language],
        },
      },
      {
        path: '/applications/:id/settings/delivery-terms',
        name: 'ApplicationsIdSettingsDeliveryTerms',
        component: SettingsDeliveryTerms,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.DeliveryTerms[Language],
        },
      },
      {
        path: '/applications/:id/settings/payment-methods',
        name: 'ApplicationsIdSettingsPaymentMethods',
        component: SettingsPaymentMethod,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.FormsOfPayment[Language],
        },
      },
      {
        path: '/applications/:id/settings/ways-of-ordering',
        name: 'ApplicationsIdSettingsWaysOfOrdering',
        component: SettingsWaysOrdering,
        layout: 'administrator',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.OrderingMethods[Language],
        },
      },
      {
        path: '/applications/:id/settings/main',
        name: Config.TextLocalization.BasicSettings[Language],
        component: SettingsBasic,
        layout: 'administrator',
        meta: {
            name: Config.TextLocalization.BasicSettings[Language],
            layoutClass: 'Settings',
        },
      },
    {
        path: '/applications/:id/settings/operation-time',
        name: 'SettingsOperationTime',
        component: SettingsOperationTime,
        layout: 'administrator',
        meta: {
            layoutClass: 'SettingsOperationTime',
            name: 'Режим работы заведения',
        },
    },
    {
        path: '/applications/:id/marketing',
        name: 'Marketing',
        component: Marketing,
        layout: 'administrator',
        meta: {
            layoutClass: 'marketing',
            name: Config.TextLocalization.Marketing[Language],
        },
    },
      {
        path: '/applications/:id/marketing/special-offers',
        name: 'ApplicationsIdBanners',
        component: SpecialOffers,
        layout: 'administrator',
        meta: {
          layoutClass: 'SpecialOffers',
          name: Config.TextLocalization.SpecialOffers[Language],
        },
      },
    {
        path: '/applications/:id/marketing/push',
        name: 'MarketingPush',
        component: MarketingPush,
        layout: 'administrator',
        meta: {
            layoutClass: 'marketing',
            name: Config.TextLocalization.PushNotifications[Language],
        },
    },
    {
        path: '/applications/:id/marketing/sms',
        name: 'MarketingSMS',
        component: MarketingSMS,
        layout: 'administrator',
        meta: {
            layoutClass: 'marketing',
            name: 'SMS рассылка',
        },
    },
    {
        path: '/applications/:id/marketing/auto-push',
        name: 'MarketingAutoPush',
        component: MarketingAutoPush,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingAutoPush',
            name: 'Автоматизированные Push-уведомления',
        },
    },
    {
        path: '/applications/:id/marketing/auto-sms',
        name: 'MarketingAutoSMS',
        component: MarketingAutoSMS,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingAutoPush',
            name: 'Автоматизированные SMS-уведомления',
        },
    },
    {
        path: '/applications/:id/marketing/promocode',
        name: 'MarketingPromocode',
        component: MarketingPromocode,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingPromocode',
            name: 'Промокоды',
        },
    },
    {
        path: '/applications/:id/marketing/promocode/add',
        name: 'MarketingPromocodeAdd',
        component: MarketingPromocodeAddEdit,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingPromocodeAdd',
            name: 'Добавить промокод',
        },
    },
    {
        path: '/applications/:id/marketing/promocode/:id',
        name: 'MarketingPromocodeEdit',
        component: MarketingPromocodeAddEdit,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingPromocodeEdit',
            name: 'Редактировать промокод',
        },
    },
    {
        path: '/applications/:id/marketing/loyalty',
        name: 'MarketingLoyalty',
        component: MarketingLoyalty,
        layout: 'administrator',
        meta: {
            layoutClass: 'marketing',
            name: Config.TextLocalization.LoyaltyProgram[Language],
        },
    },
    {
        path: '/applications/:id/marketing/widget',
        name: 'MarketingWidget',
        component: MarketingWidget,
        layout: 'administrator',
        meta: {
            layoutClass: 'marketing',
            name: Config.TextLocalization.DownloadMobileApplicationWidget[Language],
        },
    },
    {
        path: '/applications/:id/marketing/statistics',
        name: 'MarketingStatistics',
        component: MarketingStatistics,
        layout: 'administrator',
        meta: {
            layoutClass: 'SpecialOffersManager',
            name: 'Статистика',
        },
    },
      {
        path: '/applications/:id/marketing/banners/add',
        name: 'ApplicationsIdBannersAdd',
        component: SpecialOffersAddEdit,
        layout: 'administrator',
        meta: {
          layoutClass: 'SpecialOffersAddEdit',
          name: Config.TextLocalization.AddSpecialOffer[Language],
        },
      },
      {
        path: '/applications/:id/marketing/special-offers/:specialOfferID',
        name: 'ApplicationsIdBannersSpecialOfferID',
        component: SpecialOffersAddEdit,
        layout: 'administrator',
        meta: {
          layoutClass: 'SpecialOffersAddEdit',
          name: Config.TextLocalization.EditSpecialOffer[Language],
        },
      },
      {
        path: '/applications/:id/category/add',
        name: 'ApplicationsIdCategoryAdd',
        component: AddEditCategory,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditCategory',
          name: Config.TextLocalization.AddCategory[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID',
        name: 'ApplicationsIdCategoryCatID',
        component: AddEditCategory,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditCategory',
          name: Config.TextLocalization.EditCategory[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/add',
        name: 'ApplicationsIdCategoryCatIDDishAdd',
        component: AddEditDish,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditDish',
          name: Config.TextLocalization.AddDish[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/:dishID',
        name: 'ApplicationsIdCategoryCatIDDishID',
        component: AddEditDish,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditDish',
          name: Config.TextLocalization.EditDish[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/:dishID/size/add',
        name: 'ApplicationsIdCategoryCatIDDishIDSizeAdd',
        component: AddEditSize,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditSize',
          name: Config.TextLocalization.AddSize[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/:dishID/size/:sizeID',
        name: 'ApplicationsIdCategoryCatIDDishIDSizeID',
        component: AddEditSize,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditSize',
          name: Config.TextLocalization.EditSize[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/:dishID/modifier-group/add',
        name: 'ApplicationsIdCategoryCatIDDishIDModifierGroupAdd',
        component: AddEditModifierGroup,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditModifierGroup',
          name: Config.TextLocalization.AddModifierGroup[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/:dishID/modifier-group/:modifierGroupID',
        name: 'ApplicationsIdCategoryCatIDDishIDModifierGroupID',
        component: AddEditModifierGroup,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditModifierGroup',
          name: Config.TextLocalization.EditModifierGroup[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/:dishID/modifier-group/:modifierGroupID/modifier/add',
        name: 'ApplicationsIdCategoryCatIDDishIDModifierGroupModifierAdd',
        component: AddEditModifier,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditModifier',
          name: Config.TextLocalization.AddModifier[Language],
        },
      },
      {
        path: '/applications/:id/category/:catID/dish/:dishID/modifier-group/:modifierGroupID/modifier/:modifierID',
        name: 'ApplicationsIdCategoryCatIDDishIDModifierGroupModifierID',
        component: AddEditModifier,
        layout: 'administrator',
        meta: {
          layoutClass: 'AddEditModifier',
          name: Config.TextLocalization.EditModifier[Language],
        },
      },
      {
        path: '/applications/:id/finance',
        name: 'ApplicationsIdFinance',
        component: FinancePartner,
        layout: 'administrator',
        meta: {
          layoutClass: 'FinancePartner',
          name: Config.TextLocalization.PartnerFinancialReport[Language],
        },
      },
      {
        path: '/Menu',
        name: 'Menu',
        component: Menu,
        layout: 'manager',
        meta: {
          layoutClass: 'Menu',
          name: Config.TextLocalization.Menu[Language],
        },
      },
      {
        path: '/menu/category/add',
        name: 'MenuCategoryAdd',
        component: AddEditCategoryManager,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditCategory',
          name: Config.TextLocalization.AddCategory[Language],
        },
      },
      {
        path: '/menu/category/:catID',
        name: 'MenuCategoryCatID',
        component: AddEditCategoryManager,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditCategory',
          name: Config.TextLocalization.EditCategory[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/add',
        name: 'MenuCategoryCatIDDishAdd',
        component: AddEditDish,
        layout: 'manager',
        meta: {
          layoutClass: 'managerAddEditDish',
          name: Config.TextLocalization.AddDish[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/:dishID',
        name: 'MenuCategoryCatIDDishID',
        component: AddEditDish,
        layout: 'manager',
        meta: {
          layoutClass: 'managerAddEditDish',
          name: Config.TextLocalization.EditDish[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/:dishID/size/add',
        name: 'MenuCategoryCatIDDishIDSizeAdd',
        component: AddEditSize,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditSize',
          name: Config.TextLocalization.AddSize[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/:dishID/size/:sizeID',
        name: 'MenuCategoryCatIDDishIDSizeID',
        component: AddEditSize,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditSize',
          name: Config.TextLocalization.EditSize[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/:dishID/modifier-group/add',
        name: 'MenuCategoryCatIDDishIDModifierGroupAdd',
        component: AddEditModifierGroup,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditModifierGroup',
          name: Config.TextLocalization.AddModifierGroup[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/:dishID/modifier-group/:modifierGroupID',
        name: 'MenuCategoryCatIDDishIDModifierGroupModifierID',
        component: AddEditModifierGroup,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditModifierGroup',
          name: Config.TextLocalization.EditModifierGroup[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/:dishID/modifier-group/:modifierGroupID/modifier/add',
        name: 'MenuCategoryCatIDDishIDModifierGroupModifierIDModifierAdd',
        component: AddEditModifier,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditModifier',
          name: Config.TextLocalization.AddModifier[Language],
        },
      },
      {
        path: '/menu/category/:catID/dish/:dishID/modifier-group/:modifierGroupID/modifier/:modifierID',
        name: 'MenuCategoryCatIDDishIDModifierGroupModifierIDModifierID',
        component: AddEditModifier,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditModifier',
          name: Config.TextLocalization.EditModifier[Language],
        },
      },
      {
        path: '/marketing',
        name: 'Marketing',
        component: Marketing,
        layout: 'default',
        meta: {
          layoutClass: 'marketing',
          name: Config.TextLocalization.Marketing[Language],
        },
      },
    {
        path: '/legal',
        name: 'Legal',
        component: Legal,
        layout: 'no-auth-pages',
        meta: {
            layoutClass: 'Legal',
            name: 'Юридическая информация',
        },
    },
    {
        path: '/legal/privacy-policy',
        name: 'PrivacyPolicy',
        component: LegalPrivacyPolicy,
        layout: 'no-auth-pages',
        meta: {
            layoutClass: 'PrivacyPolicy',
            name: 'Политика конфиденциальности',
        },
    },
    {
        path: '/legal/tariffs',
        name: 'LegalTariffs',
        component: LegalTariffs,
        layout: 'no-auth-pages',
        meta: {
            layoutClass: 'LegalTariffs',
            name: 'Действующие тарифы ООО "ГастроСофт"',
        },
    },
    {
        path: '/legal/public-contract',
        name: 'PublicContract',
        component: LegalPublicContract,
        layout: 'no-auth-pages',
        // auth: false,
        meta: {
            layoutClass: 'PublicContract',
            name: 'Публичный договор',
        },
    },
      {
        path: '/contacts',
        component: Contacts,
        name: 'Contacts',
        layout: 'manager',
        meta: {
          layoutClass: 'Contacts',
          name: 'Контакты',
        },
      },
      {
        path: '/marketing/push',
        name: 'MarketingPush',
        component: MarketingPush,
        layout: 'manager',
        meta: {
          layoutClass: 'marketing',
          name: Config.TextLocalization.PushNotifications[Language],
        },
      },
      {
        path: '/marketing/sms',
        name: 'MarketingSMS',
        component: MarketingSMS,
        layout: 'manager',
        meta: {
          layoutClass: 'marketing',
          name: 'SMS рассылка',
        },
      },
      {
        path: '/marketing/auto-push',
        name: 'MarketingAutoPush',
        component: MarketingAutoPush,
        layout: 'manager',
        meta: {
          layoutClass: 'MarketingAutoPush',
          name: 'Автоматизированные Push-уведомления',
        },
      },
      {
        path: '/marketing/auto-sms',
        name: 'MarketingAutoSMS',
        component: MarketingAutoSMS,
        layout: 'manager',
        meta: {
          layoutClass: 'MarketingAutoPush',
          name: 'Автоматизированные SMS-уведомления',
        },
      },
      {
        path: '/marketing/promocode',
        name: 'MarketingPromocode',
        component: MarketingPromocode,
        layout: 'manager',
        meta: {
          layoutClass: 'MarketingPromocode',
          name: 'Промокоды',
        },
      },
    {
        path: '/marketing/promocode/add',
        name: 'MarketingPromocodeAdd',
        component: MarketingPromocodeAddEdit,
        layout: 'manager',
        meta: {
            layoutClass: 'MarketingPromocodeAdd',
            name: 'Добавить промокод',
        },
    },
    {
        path: '/marketing/promocode/:id',
        name: 'MarketingPromocodeEdit',
        component: MarketingPromocodeAddEdit,
        layout: 'manager',
        meta: {
            layoutClass: 'MarketingPromocodeEdit',
            name: 'Редактировать промокод',
        },
    },

      {
        path: '/marketing/templates-special-offers',
        name: 'MarketingTemplatesSpecialOffers',
        component: MarketingTemplatesSpecialOffers,
        layout: 'administrator',
        meta: {
          layoutClass: 'MarketingTemplatesSpecialOffers',
          name: 'Шаблоны специальных предложений',
        },
      },
    {
        path: '/marketing/templates-automatic-push',
        name: 'MarketingTemplatesAutomaticPush',
        component: MarketingTemplatesAutomaticPush,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingTemplatesAutomaticPush',
            name: 'Шаблоны автоматических пуш уведомлений',
        },
    },
    {
        path: '/marketing/templates-automatic-push/add',
        name: 'MarketingTemplatesAutomaticPushAdd',
        component: MarketingTemplatesAutomaticPushCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingTemplatesAutomaticPushAdd',
            name: 'Добавить шаблон автоматического пуш уведомления',
        },
    },
    {
        path: '/marketing/templates-automatic-push/:id',
        name: 'MarketingTemplatesAutomaticPushEdit',
        component: MarketingTemplatesAutomaticPushCard,
        layout: 'administrator',
        meta: {
            layoutClass: 'MarketingTemplatesAutomaticPushEdit',
            name: 'Редактировать шаблон автоматического пуш уведомления',
        },
    },
      {
        path: '/marketing/templates-segments',
        name: 'MarketingTemplatesSegments',
        component: MarketingTemplatesSegments,
        layout: 'administrator',
        meta: {
          layoutClass: 'MarketingTemplatesSegments',
          name: 'Шаблоны аудиторий',
        },
      },
      {
        path: '/marketing/templates-segments/add',
        name: 'MarketingTemplatesSegmentAdd',
        component: MarketingTemplatesSegmentEdit,
        layout: 'administrator',
        meta: {
          layoutClass: 'MarketingTemplatesSegmentAdd',
          name: 'Добавление шаблона аудитории',
        },
      },
      {
        path: '/marketing/templates-segments/:id',
        name: 'MarketingTemplatesSegmentID',
        component: MarketingTemplatesSegmentEdit,
        layout: 'administrator',
        meta: {
          layoutClass: 'MarketingTemplatesSegmentEdit',
          name: 'Редактирование шаблона аудитории',
        },
      },
      {
        path: '/marketing/loyalty',
        name: 'MarketingLoyalty',
        component: MarketingLoyalty,
        layout: 'manager',
        meta: {
          layoutClass: 'marketing',
          name: Config.TextLocalization.LoyaltyProgram[Language],
        },
      },
      {
        path: '/marketing/widget',
        name: 'MarketingWidget',
        component: MarketingWidget,
        layout: 'manager',
        meta: {
          layoutClass: 'marketing',
          name: Config.TextLocalization.DownloadMobileApplicationWidget[Language],
        },
      },
      {
        path: '/clients',
        name: 'Clients',
        component: MarketingClients,
        layout: 'manager',
        meta: {
          layoutClass: 'marketing',
          name: Config.TextLocalization.CustomerBase[Language],
        },
      },
      {
        path: '/clients/segment/add',
        name: 'ClientsSegmentAdd',
        component: SegmentAddEdit,
        layout: 'manager',
        meta: {
          layoutClass: 'SegmentAdd',
          name: 'Добавление шаблона',
        },
      },
      {
        path: '/clients/segment/:id',
        name: 'ClientsSegmentID',
        component: SegmentAddEdit,
        layout: 'manager',
        meta: {
          layoutClass: 'SegmentEdit',
          name: 'Редактирование шаблона',
        },
      },
      {
        path: '/marketing/special-offers',
        name: 'MarketingSpecialOffers',
        component: SpecialOffersManager,
        layout: 'manager',
        meta: {
          layoutClass: 'SpecialOffersManager',
          name: Config.TextLocalization.SpecialOffers[Language],
        },
      },
      {
        path: '/marketing/statistics',
        name: 'MarketingStatistics',
        component: MarketingStatistics,
        layout: 'manager',
        meta: {
          layoutClass: 'SpecialOffersManager',
          name: 'Статистика',
        },
      },
      {
        path: '/marketing/special-offers/add',
        name: 'MarketingSpecialOffersAdd',
        component: SpecialOffersAddEdit,
        layout: 'manager',
        meta: {
          layoutClass: 'SpecialOffersAddEditManager',
          name: Config.TextLocalization.AddSpecialOffer[Language],
        },
      },
      {
        path: '/marketing/special-offers/:specialOfferID',
        name: 'MarketingSpecialOffersID',
        component: SpecialOffersAddEdit,
        layout: 'manager',
        meta: {
          layoutClass: 'SpecialOffersAddEditManager',
          name: Config.TextLocalization.EditSpecialOffer[Language],
        },
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
        layout: 'administrator',
        meta: {
          layoutClass: 'Users',
          name: Config.TextLocalization.Users[Language],
        },
      },
      {
        path: '/users/add',
        name: 'UsersAdd',
        component: AddEditUsers,
        layout: 'administrator',
        meta: {
          layoutClass: 'SystemUsers',
          name: Config.TextLocalization.AddUser[Language],
        },
      },
      {
        path: '/users/:contactID',
        name: 'UsersID',
        component: AddEditUsers,
        layout: 'administrator',
        meta: {
          layoutClass: 'SystemUsers',
          name: Config.TextLocalization.EditUser[Language],
        },
      },
      {
        path: '/billing',
        name: 'Billing',
        component: Billing,
        layout: 'manager',
        meta: {
          layoutClass: 'Billing',
          name: Config.TextLocalization.Payments[Language],
        },
      },
      {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        layout: 'manager',
        meta: {
          layoutClass: 'Orders',
          name: Config.TextLocalization.Orders[Language],
        },
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        layout: 'manager',
        meta: {
          layoutClass: 'Settings',
          name: Config.TextLocalization.Settings[Language],
        },
      },
      {
        path: '/settings/api',
        name: 'SettingsAPI',
        component: SettingsAPI,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsAPI',
          name: Config.TextLocalization.APIIntegration[Language],
        },
      },
      {
        path: '/settings/order-transfer-methods',
        name: 'SettingsOrderTransferMethods',
        component: SettingsOrderTransfer,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsOrderTransfer',
          name: Config.TextLocalization.OrderTransferMethods[Language],
        },
      },
      {
        path: '/settings/social-media',
        name: 'SettingsSocialMedia',
        component: SettingsSocialMedia,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsSocialMedia',
          name: Config.TextLocalization.SocialNetworks[Language],
        },
      },

      {
          path: '/settings/operation-time',
          name: 'SettingsOperationTime',
          component: SettingsOperationTime,
          layout: 'manager',
          meta: {
              layoutClass: 'SettingsOperationTime',
              name: 'Режим работы заведения',
          },
      },
      {
        path: '/settings/users',
        name: 'SettingsUsers',
        component: Users,
        layout: 'manager',
        meta: {
          layoutClass: 'Users',
          name: Config.TextLocalization.Users[Language],
        },
      },
      {
        path: '/settings/users/add',
        name: 'SettingsUsersAdd',
        component: AddEditUsers,
        layout: 'manager',
        meta: {
          layoutClass: 'SystemUsers',
          name: Config.TextLocalization.AddUser[Language],
        },
      },
      {
        path: '/settings/users/:contactID',
        name: 'SettingsUsersID',
        component: AddEditUsers,
        layout: 'manager',
        meta: {
          layoutClass: 'SystemUsers',
          name: Config.TextLocalization.EditUser[Language],
        },
      },
      {
        path: '/settings/delivery-zone',
        name: 'SettingsDeliveryZone',
        component: SettingsDeliveryZone,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsDeliveryZone',
          name: Config.TextLocalization.DeliveryZones[Language],
        },
      },
      {
        path: '/settings/branch',
        name: 'SettingsBranch',
        component: SettingsBranches,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsBranches',
          name: Config.TextLocalization.BranchesOfTheOrganization[Language],
        },
      },
      {
        path: '/settings/branch/add',
        name: 'SettingsBranchAdd',
        component: AddEditBranch,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditBranch',
          name: Config.TextLocalization.AddBranch[Language],
        },
      },
      {
        path: '/settings/branch/:branchID',
        name: 'SettingsBranchID',
        component: AddEditBranch,
        layout: 'manager',
        meta: {
          layoutClass: 'AddEditBranch',
          name: Config.TextLocalization.EditBranch[Language],
        },
      },
      {
        path: '/settings/delivery-terms',
        name: 'SettingsDeliveryTerms',
        component: SettingsDeliveryTerms,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsDeliveryZone',
          name: Config.TextLocalization.DeliveryTerms[Language],
        },
      },
      {
        path: '/settings/payment-methods',
        name: 'SettingsPaymentMethod',
        component: SettingsPaymentMethod,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsPaymentMethod',
          name: Config.TextLocalization.FormsOfPayment[Language],
        },
      },
      {
        path: '/settings/ways-of-ordering',
        name: 'SettingsWaysOrdering',
        component: SettingsWaysOrdering,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsWaysOrdering',
          name: Config.TextLocalization.OrderingMethods[Language],
        },
      },
      {
        path: '/settings/main',
        name: 'SettingsMain',
        component: SettingsBasic,
        layout: 'manager',
        meta: {
          layoutClass: 'SettingsBasic',
          name: Config.TextLocalization.BasicSettings[Language],
        }
      }
    ];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
  route.meta = route.meta || {} ;
  route.meta.layout = route.layout || parentLayout ;

  if( route.children )
  {
    route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
  }
  return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if ( to.hash ) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    }
  }
})

export default router

