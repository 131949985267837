<template>
    <div>
      <div class="menu_category" v-for="(category, catIndex) in catalog" :key="catIndex" @click="open_category(category.id)">
        <h6>{{ category.name }} <span class="edit">({{ TextLocalization.Edit[language] }})</span></h6>
      </div>
      <div class="create_category" @click="open_category('add')">
        {{ TextLocalization.AddCategory[language] }}
      </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import MenuAPI from "../../../api/MenuAPI";

export default {
  data(){
    return {
      restaurant_id: null,
      catalog: null
    }
  },
  watch:{
    getUserInfo: {
      handler(val) {
        switch (val.type){
          case 0:
            this.restaurant_id = this.$route.params.id;
            this.is_admin = true;
            break;
          case 1:
            this.restaurant_id = val.partner_id;
            this.is_admin = false;
            break;
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters({
      getUserInfo: 'User/getUserInfo'
    })
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo(){
      MenuAPI.get_catalog(this.restaurant_id)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.catalog = response.data.categories;
                break;
              default:
                this.catalog = []
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    open_category: function (id){
      switch(this.$route.meta.layout){
        case 'administrator':
          this.goTo('/applications/'+this.restaurant_id+'/category/'+id);
          break;
        case 'manager':
          this.goTo('/menu/category/'+id);
          break;
      }
    },
    goTo: function (data){
      this.$router.push(data);
    },
  }
}
</script>

<style scoped lang="scss">
.menu_category, .create_category{
  margin: 4px;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  width: 100%;
  padding: 15px;
  position: relative;
}
.create_category{
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(24, 144, 255, 0.15);
    border: 1px dashed #1890FF;
    text-decoration: underline;
  }
}
.menu_category{
  &:hover {
    border: 1px dashed #1890FF;
    background-color: rgba(24, 144, 255, 0.15);
    cursor: pointer;
  }
  & h6{
    margin-bottom: 10px;
    margin-left: 20px;

    & .edit {
      font-size: 80%;
      font-weight: initial;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
</style>