import BaseApi from "./BaseApi";
import partnerID from "@/mixins/partnerID";

class ApplicationApi extends BaseApi {

    get_all() {
        return this.axios.get('/application/');
    }

    get_data(id) {
        return this.axios.get('/application/'+id);
    }

    get_documents(id) {
        return this.axios.get('/application/'+id+'/documents');
    }

    get_settings(id) {
        return this.axios.get('/application/'+id+'/Settings');
    }

    edit_settings(data) {
        return this.axios.post('/application/'+data.partner_id+'/settings', data);
    }

    edit_data(data) {
        delete data.token;
        return this.axios.post('/application/edit', data);
    }

    add_data(data) {
        return this.axios.post('/application/add', data);
    }

    delete(data){
        return this.axios.post('/application/delete', data);
    }

    editOperationTime(data){
        return this.edit_settings({
            id: data.id,
            partner_id: data.partner_id,
            operation_time: {timesheet: data.timesheet}
        })
    }

    getApplicationTariffsAvailable(){
        return this.axios.get('/settings/application-tariff');
    }

    get_application_version (partnerID){
        return this.axios.get('/settings/application-versions/'+partnerID);
    }

    get_application_tariff (partnerID){
        return this.axios.get('/settings/application-tariff/'+partnerID);
    }
}

export default new ApplicationApi();
